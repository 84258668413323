import React from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from '@contentful/rich-text-types';

import Modal from "../../atoms/modal/modal";

import "./modalImage.scss";

const ModalImage = (props) => {
  const { open, handleClose, imageUrl, footer = "" } = props;

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => (
        <p className="m-modal-image__desc">{children}</p>
      )
    }
  }

  return (
    <Modal open={open} handleClose={handleClose}>
      <img
        className="m-modal-image__image"
        src={imageUrl}
        alt="Detalle imagen"
      />
      {footer &&
        documentToReactComponents(JSON.parse(footer.raw), options)
      }
    </Modal>
  )
}

export default ModalImage;