import React from "react";
import "./modal.scss";

const Modal = (props) => {
  const { open, handleClose, children } = props;

  return (
    <div className={open ? "a-modal" : "a-modal a-modal--hide"}>
      <section className="a-modal__main">
        {children}
        <button
          className="icon-close a-modal__close"
          type="button"
          onClick={handleClose}
        />
      </section>
    </div>
  )
}

export default Modal;