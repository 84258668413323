import React, { useState } from "react";
import ImageGallery from 'react-image-gallery';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import DangerImage from "../../../assets/images/peligro.png";
import CautionImage from "../../../assets/images/precaucion.png";
import ModalImage from "../../molecules/modal-image/modalImage";

import "./productDetailTemplate.scss";

const Section = (props) => {
  const {
    title,
    description = "",
    optionsDtR = "",
    prominentMessage = "",
    prominentType = "",
    images = [],
    footerImages = "",
    options = []
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [urlImageModal, setUrlImageModal] = useState("");

  const handleShowModal = (image) => {
    if (image !== undefined) setUrlImageModal(_ => image.file.url);
    document.body.classList = showModal ? "" : "modal-open";
    setShowModal(show => !show);
  }

  return (
    <section className="t-product-detail__section">
      <h2 className="t-product-detail__section__title">
        {title}
      </h2>
      <hr />
      <div className="t-product-detail__section__content">
        {description.raw &&
          <div
            className={`t-product-detail__section__content__desc ${
              prominentMessage ? "" : "t-product-detail__section__content__desc--full-width"
            }`}
          >
            {documentToReactComponents(JSON.parse(description.raw), optionsDtR)}
          </div>
        }
        {prominentType &&
          <div className="t-product-detail__section__content__prominent">
            {documentToReactComponents(JSON.parse(prominentMessage.raw), optionsDtR)}
            <img
              className="t-product-detail__section__content__prominent__image"
              src={prominentType === "Peligro" ? DangerImage : CautionImage}
              alt="Imagen destacado"
            />
          </div>
        }
        {images.length > 0 &&
        <>
          <p className="t-product-detail__desc">
            Haz clic en la imagen para ampliar
          </p>
          <div className="t-product-detail__section__content__boards">
            {images.map((image, i) => (
              <img
                key={image.file.fileName}
                src={image.file.url}
                alt={image.file.fileName}
                onClick={() => handleShowModal(image)}
              />
            ))}
          </div>
        </>
        }
        {options.length > 0 &&
          <div className="t-product-detail__section__content__options">
            {options.map((item) => (
              <div
                key={item.name}
                className="t-product-detail__section__content__option"
              >
                <div className="t-product-detail__section__content__option__images">
                  <img
                    className="t-product-detail__section__content__option__images__main"
                    src={item.mainImage.file.url}
                    alt={`Imagen principal ${item.name}`}
                    onClick={() => handleShowModal(item.mainImage)}
                  />
                  {item.secondayImage &&
                    <img
                      className="t-product-detail__section__content__option__images__secondary"
                      src={item.secondayImage.file.url}
                      alt={`Imagen secundaria ${item.name}`}
                    />
                  }
                </div>
                <h3 className="t-product-detail__section__content__option__title">
                  {item.name}
                </h3>
                {documentToReactComponents(JSON.parse(item.description.raw), optionsDtR)}
                {item.board &&
                <>
                  <img
                    className="t-product-detail__section__content__option__board"
                    src={item.board.file.url}
                    alt="Tabla opción"
                    onClick={() => handleShowModal(item.board)}
                  />
                  <div className="t-product-detail__section__content__option__footer">
                    {documentToReactComponents(JSON.parse(item.footerBoardInfo.raw), optionsDtR)}
                  </div>
                </>
                }
              </div>
            ))}
          </div>
        }
        <ModalImage
          open={showModal}
          handleClose={() => handleShowModal()}
          imageUrl={urlImageModal}
          footer={footerImages}
        />
      </div>
    </section>
  )
}

const ProductDetailTemplate = (props) => {
  const {
    applicationExamples,
    blueprints,
    boards,
    cards,
    description,
    extraBoards,
    extraInfo,
    footerBoards,
    footerExtraBoards,
    options,
    photos,
    productName,
    prominentMessage,
    prominentType,
    selectionMatrix,
    showContact,
    showCustomContact
  } = props;

  const images = photos.map((photo) => (
    {
      original: photo.file.url,
      thumbnail: photo.file.url,
      originalClass: "t-product-detail__main__images__image",
      thumbnailClass: "t-product-detail__main__images__thumbnail"
    }
  ));

  const cardsOptions = {
    renderMark: {
      [MARKS.UNDERLINE]: text => <><span>{text}</span><br /></>
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => (
        <p className="t-product-detail__main__desc__card">{children}</p>
      )
    }
  }

  const generalOptions = {
    renderMark: {
      [MARKS.UNDERLINE]: text => <><span>{text}</span><br /></>,
      [MARKS.BOLD]: text => <strong>{text}</strong>
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_, children) => {
        if (children) return <p className="t-product-detail__desc">{children}</p>
      },
      [BLOCKS.LIST_ITEM]: (_, children) => {
        return <li className="t-product-detail__list-item">{children}</li>
      }
    }
  }

  return (
    <div className="t-product-detail">
      <section className="t-product-detail__main">
        <div>
          <ImageGallery
            items={images}
            showNav={false}
            showFullscreenButton={false}
            showPlayButton={false}
            additionalClass="t-product-detail__main__images"
          />
        </div>
        <div className="t-product-detail__main__desc">
          <h1 className="t-product-detail__main__desc__name">
            {productName}
          </h1>
          <div className="t-product-detail__main__desc__cards">
            {documentToReactComponents(JSON.parse(cards.raw), cardsOptions)}
          </div>
          {showContact &&
            <>
              <a
                className="t-product-detail__main__desc__contact"
                href="/contacto"
              >
                Contactar con Dicar Importadora S.A.S. <span className="icon-chevron-right" />
              </a>
              <hr />
            </>
          }
          {showCustomContact &&
            <a
              className="t-product-detail__main__desc__contact"
              href="/contacto-personalizado"
            >
              Solicitar una versión personalizada <span className="icon-chevron-right" />
            </a>
          }
        </div>
      </section>
      {description &&
        <Section
          title="Descripción"
          description={description}
          optionsDtR={generalOptions}
          prominentMessage={prominentMessage}
          prominentType={prominentType}
        />
      }
      {blueprints &&
        <Section
          title="Planos"
          images={blueprints}
        />
      }
      {boards &&
        <Section
          title="Datos comparativos"
          images={boards}
          footerImages={footerBoards}
        />
      }
      {extraInfo &&
        <Section
          title="Información adicional"
          description={extraInfo}
          optionsDtR={generalOptions}
        />
      }
      {extraBoards &&
        <Section
          title="Especifícaciones"
          images={extraBoards}
          footerImages={footerExtraBoards}
        />
      }
      {options &&
        <Section
          title="Opciones y/o accesorios"
          options={options}
          optionsDtR={generalOptions}
        />
      }
      {selectionMatrix &&
        <Section
          title="Matriz de selección para pedidos"
          images={selectionMatrix}
        />
      }
      {applicationExamples &&
        <Section
          title="Ejemplos de aplicación"
          images={applicationExamples}
        />
      }
    </div>
  )
}

export default ProductDetailTemplate;