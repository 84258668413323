import React from "react";
import Layout from "../components/organisms/layout/layout";
import ProductDetailTemplate from "../components/templates/product-detail-template/productDetailTemplate";

const ProductDetail = (props) => {
  return (
    <Layout title={`Dicar | Producto - ${props.pageContext.productName}`}>
      <ProductDetailTemplate {...props.pageContext}/>
    </Layout>
  )
}

export default ProductDetail;